.App {
    display: flex;
    width: 100%;
    align-items: stretch;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
    font-family: "Poppins", sans-serif;
    background: #fafafa;
    /*min-width: 520px;*/
}

a ,
a:hover,
a:focus {
    color: #fff;
    text-decoration: none;
    transition: all 0.3s;
}

a:visited {
    color: blue;
}

::-webkit-scrollbar {
    width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
    background: white;
    box-shadow: inset 0 0 3px grey;
    border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: darkgray;
    border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0474d7;
}

/* ---------------------------------------------------
    GENERAL STYLE
----------------------------------------------------- */
.two-comp-wrap {
    display: flex;
}

.loading-animation-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: bold;
}

/* ---------------------------------------------------
    SIGN IN PAGE STYLE
----------------------------------------------------- */
.auth-signin-container {
    margin: auto;
    margin-top: 50px;
    width: 40%;
    background-color: white;
    border: 1.5px solid darkslategray;
    border-radius: 10px;
    padding-top: 5px;
    text-align: center;
    
    min-width: 325px;
    max-width: 450px;

    min-height: 420px;
}

.auth-input {
    margin: auto;
    width: 70% !important;
}

.auth-input-label {
    font-weight: bold;
}

.auth-error {
    margin: 20px 80px 10px 80px;
    border-radius: 5px;
    background-color: #f6c343;
    font-weight: bold;
}

.signin-container {
    overflow: hidden;
}

.signin-subcontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.signin-background {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(53,151,255);
    background: linear-gradient(90deg, rgba(53,151,255,1) 0%, rgba(41,193,254,1) 100%);
    z-index: -1;
}

.signin-logo-container {

}

.signin-logo-text {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-top: -20px;
}

.signin-info-container {
    /*border: 1px solid gray;*/
    border-radius: 10px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 90%;
    height: 50px;

    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.signin-llc-container {

    text-align: center;
    font-weight: 600;
}

.signin-llc {
    margin-left: auto;
    margin-right: auto;

    color: white;
    font-size: 14px;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar-apps-header {
    margin: 5px 0px 5px 16px;
    cursor: default;
    padding-bottom: 3px;
    font-weight: bold;

}

.sidebar {
    min-width: 250px;
    max-width: 250px;
    margin-left: -250px;

    background: #05aff2;
    color: #fff;
    

    transition: all 0.5s;

    min-height: 100vh;

    z-index: 10;

    position: absolute;
}

.sidebar-item {
    font-weight: bold;
    font-size: 16px;
    color: #fff !important;
    margin-right: 150px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin: 10px 150px 10px 15px;

    cursor: pointer;
    border-radius: 0px 10px 10px 0px;
}

@media screen and (max-width: 479px) {
    .sidebar-item {
        color: black !important;
        font-size: 16px;
        font-weight: bold;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        transition: all 0.5s;
        border-radius: 10px 10px 10px 10px;
        background-color: white;
        padding: 5px 50px 5px 50px;
    }

    .sidebar-apps-header {
        font-weight: bold;
        margin: 5px 0px 5px 16px;
        cursor: default;
        padding-bottom: 3px;
        transition: all 0.5s;
    }

    .submenu-items.nav-item {
        color: black !important;
        padding: 5px 50px 5px 50px;
        margin: 10px 30px 10px 30px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 10px 10px 10px 10px;
        background-color: white;
        white-space: nowrap;
    }

    .static-menu.is-open {
        margin-left: 0px;
        transition: 0.4s;
    }

    .static-menu {
        /*min-width: 325px;
        max-width: 325px;*/
        background: #0597f2;
        border-radius: 0px 10px 10px 0px;
        margin-right: 20px;
        margin-left: -350px;
        padding-top: 5px;
        padding-bottom: 8px;
        transition: all 0.4s;
    }
}

.sidebar-item:hover {
    /*color: #0474d7 !important;
    background: #fff;
    border-radius: 0px 10px 10px 0px;*/
}

.sidebar-spacing {
    justify-content: space-between;
}

.sidebar.is-open {
    margin-left: 0px;
    transition: 0.5s;
}

.sidebar-header {
    background: #0474d7;
    min-height: 100px;
    max-height: 100px;
}

.sidebar-header h3 {
    color: #fff;
    padding: 1em;
}

.static-menu {
    background: #0597f2;
    border-radius: 0px 10px 10px 0px;
    margin-right: 20px;
    padding-top: 5px;
    padding-bottom: 8px;
}

.menu-open {
    background: #0597f2;
    border-radius: 0px 10px 10px 0px;
    margin-right: 40px;
}

.menu-closed {
    background: #0597f2;
    border-radius: 0px 10px 10px 0px;
}

li.submenu-items.nav-item {
    color: #fff;
}

li a .nav-link {
    padding-left: 15px;
}

.submenu-items {
    font-weight: bold;
    padding-left: 45px;
    margin: 10px 40px 10px 0px;
    color: #fff !important;
    border-radius: 0px 10px 10px 0px;

    cursor: pointer;
    /*transition: all 0.3s;*/
}

.submenu-items:hover {
    /*color: #0474d7 !important;
    background-color: #fff;
    border-radius: 0px 10px 10px 0px;*/
}

.submenu-items.nav-link {
    color: #fff !important;
    
}

.submenu-header:hover {
    border-radius: 0px 10px 10px 0px;
    border-color: #fff;
}

.submenu-collapsed {
    border-radius: 0px 10px 10px 0px;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
}

.sidebar-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}

.sidebar-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    z-index: 15;
}

.sidebar-footer {
    position: fixed;
    bottom: 0;
}

.sidebar-footer-item {
    padding-left: 5px;
    margin: 0px 40px 5px 6px;
    color: #fff !important;
    
    
    font-weight: bold;
}

.side-menu::-webkit-scrollbar {
    width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
    background: #5466b3;
    border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
    background: #3a4c99;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
    height: 100vh;
    width: 100%;
    top: 300px;
}

@media only screen and (max-width: 500px) {
    .content.is-open {
        margin-left: 100%;
    }

    .sidebar.is-open {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        transition: all 0.5s, height 0s;
    }

    sidebar.is-open > .sidebar-header span {
        display: unset;

    }

    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }

    .nav {
        --bs-nav-link-color: null;
        justify-content: center;
    }
}

/* ---------------------------------------------------
    TABLE STYLE
----------------------------------------------------- */
.table-header-fit {
    width: 1%;
    white-space: nowrap;
}

.styled-table {
    width: 100%; /* Table takes full width */
    border-collapse: collapse; /* Prevent double borders */
    margin: 20px 0; /* Optional: Add margin to the table */
    font-size: 16px;
    text-align: center;
    table-layout: fixed;
}

/* Add borders for horizontal and vertical lines */
.styled-table th,
.styled-table td {
    border: 1px solid lightgray; /* Horizontal and vertical grid lines */
    padding: 8px; /* Space around cell contents */
}

/* Optional: Add styling for table header */
.styled-table th {
    background-color: #f4f4f4; /* Header background color */
    font-weight: bold;
}

